<template>
    <component :is="userData === undefined ? 'div' : 'b-card'" :title="$t('Profile Info')">
        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="userData === undefined"
        >
            <h4 class="alert-heading">
                Error fetching user data
            </h4>
            <div class="alert-body">
                No user found with this user id. Check
                <b-link
                    class="alert-link"
                    :to="{ name: 'apps-users-list'}"
                >
                    User List
                </b-link>
                for other users.
            </div>
        </b-alert>
        <!--    User Data Inputs    -->
        <div v-if="userData">
            <!-- Media -->
            <b-media class="mb-2">
                <template #aside>
                    <b-avatar
                        ref="previewEl"
                        :src="currentImage? currentImage:userData.full_path"
                        :text=" avatarText(currentUserData.first_name + ' ' + currentUserData.last_name) "
                        size="90px"
                        rounded
                    />
                </template>
                <h4 class="mb-1">
                    {{ currentUserData.first_name }} {{ currentUserData.last_name }}
                </h4>
                <div class="d-flex flex-wrap">
                    <b-button
                        variant="primary"
                        @click="$refs.refInputEl.click()"
                    >
                        <input
                            ref="refInputEl"
                            type="file"
                            class="d-none"
                            @change="uploadUserImage"
                        >
                        <span class="d-none d-sm-inline">Update</span>
                        <feather-icon
                            icon="EditIcon"
                            class="d-inline d-sm-none"
                        />
                    </b-button>
                    <b-button
                        variant="outline-secondary"
                        class="ml-1"
                    >
                        <span class="d-none d-sm-inline">Remove</span>
                        <feather-icon
                            icon="TrashIcon"
                            class="d-inline d-sm-none"
                        />
                    </b-button>
                </div>
            </b-media>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="$t('First Name')"
                            label-for="mc-first-name"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|alpha"
                                :name="$t('First Name')"
                            >
                                <b-form-input
                                    id="mc-first-name"
                                    :placeholder="$t('First Name')"
                                    v-model="currentUserData.first_name"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            :label="$t('Last Name')"
                            label-for="mc-last-name"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|alpha"
                                :name="$t('Last Name')"
                            >
                                <b-form-input
                                    id="mc-last-name"
                                    :placeholder="$t('Last Name')"
                                    v-model="currentUserData.last_name"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            :label="$t('Email')"
                            label-for="mc-email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|email"
                                :name="$t('Email')"
                            >
                                <b-form-input
                                    id="mc-email"
                                    :placeholder="$t('Email')"
                                    v-model="currentUserData.email"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            :label="$t('Phone')"
                            label-for="mc-phone"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|integer"
                                :name="$t('Phone')"
                            >
                                <b-form-input
                                    id="mc-phone"
                                    :placeholder="$t('Phone')"
                                    v-model="currentUserData.phone"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <div class="divider mt-0 mb-2">
                            <div class="divider-text p-0">
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="justify-content-center">
                    <b-button type="submit"
                              variant="primary"
                              v-on:click="updateUserInfo"
                    >
                        {{ $t('Update My Information') }}
                    </b-button>
                </b-row>
            </validation-observer>
        </div>

    </component>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BCard, BAlert, BLink, BButton, BMedia, BAvatar, BCol, BRow, BFormInput, BFormGroup,} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {
    required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

export default {
    name: "profile",
    data() {
        return {
            currentImage: '',
            currentImageServerPath: '',
            currentUserData: {
                id: '',
                first_name: "",
                last_name: "",
                profile_image: null,
                email: "",
                phone: null,
            },
        }
    },
    components: {
        BCard, BAlert,
        BLink, BButton,
        BMedia,
        BAvatar, BCol, BRow,
        ValidationProvider,
        ValidationObserver, BFormInput, BFormGroup,
    },
    computed: {
        userData() {
            return this.$store.state.user.userData;
        },
    },
    methods: {
        avatarText(value) {
            if (!value) return ''
            const nameArray = value.split(' ')
            return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
        },
        uploadUserImage(value) {
            let formData = new FormData();
            formData.append("file", value.target.files[0]);
            this.$store.dispatch("user/uploadUserImage", formData)
                .then(res => {
                    this.currentImage = res.data.full_path;
                    this.currentImageServerPath = res.data.filePath;
                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                })
        },
        updateUserInfo() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    let myPayload = {
                        first_name: this.currentUserData.first_name,
                        last_name: this.currentUserData.last_name,
                        phone: this.currentUserData.phone,
                        email: this.currentUserData.email,
                        profile_image: this.currentImageServerPath ? this.currentImageServerPath : this.userData.profile_image
                    }
                    this.$store.dispatch("user/updateUserInfo", myPayload)
                        .then(res => {
                            this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: this.$t('Successfully'),
                                        icon: 'InfoIcon',
                                        text: this.$t('Your Account Info has been updated successfully'),
                                        variant: 'success',
                                    },
                                },
                                {
                                    position: 'bottom-right',
                                })
                        })
                        .catch(err => {
                            this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: this.$t('Error'),
                                        icon: 'InfoIcon',
                                        text: err.response.data.message,
                                        variant: 'danger',
                                    },
                                },
                                {
                                    position: 'bottom-right',
                                })
                        })
                } else {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: this.$t('Please Check Entered Data'),
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                }
            })
        },
    },
    created() {
        this.$store.dispatch("user/getUserData")
            .then(res => {
                let item = res.data;
                this.currentUserData.id = item.id;
                this.currentUserData.email = item.email;
                this.currentUserData.phone = item.phone;
                this.currentUserData.last_name = item.last_name;
                this.currentUserData.first_name = item.first_name;
                this.currentUserData.profile_image = item.profile_image;
            })
            .catch(err => {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Error'),
                            icon: 'InfoIcon',
                            text: err.response.data.message,
                            variant: 'danger',
                        },
                    },
                    {
                        position: 'bottom-right',
                    })
            })
    },
}
</script>

<style scoped>

</style>
